<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('create_classes')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('create_classes')"
                              :isFilter="false"/>
            </template>
            <div class="row mb-5 align-items-center">
                <div class="col-12">
                    <ValidationObserver ref="storeForm">
                        <div class="row">
                            <div class="col-6 col-lg-2 col-md-4 col-xs-12">
                                <ValidationProvider name="level" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('level')">
                                        <parameter-selectbox
                                            :validate-error="errors[0]"
                                            code="prep_levels"
                                            v-model="form.level"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-6 col-lg-2 col-md-4 col-xs-12">
                                <ValidationProvider name="number_of_students" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('number_of_students')">
                                        <b-form-input
                                            type="number" step="1"
                                            v-model="form.number_of_students"
                                            min="0"
                                            v-check-min-max-value>
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-6 col-lg-2 col-md-4 col-xs-12">
                                <ValidationProvider name="from_score" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('from_score')">
                                        <b-form-input
                                            type="number" step=".25" min="0"
                                            v-check-min-max-value
                                            v-model="form.from_score">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-6 col-lg-2 col-md-4 col-xs-12">
                                <ValidationProvider name="to_score" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('to_score')">
                                        <b-form-input
                                            type="number" step=".25"
                                            min="0"
                                            v-check-min-max-value
                                            v-model="form.to_score">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-6 col-lg-2 col-md-4 col-xs-12">
                                <ValidationProvider name="order_method" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('order')">
                                        <b-form-select
                                            :options="orderOptions"
                                            v-model="form.order_by">
                                        </b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                        </div>
                    </ValidationObserver>
                </div>
                <div class="col-12 mt-3 d-flex">
                    <b-button @click="store"
                              type="button"
                              variant="primary"
                              class="btn-lg mr-2">
                        {{ $t('create_classes') }}
                    </b-button>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header';
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import PrepStudentService from '@/services/PrepStudentService';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            ParameterSelectbox,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                form: {},
                orderOptions: [
                    {value: '', text: this.$t('select')},
                    {value: 'student_number', text: this.$t('student_number')},
                    {value: 'name', text: this.$t('name')},
                    {value: 'surname', text: this.$t('surname')},
                    {value: 'random', text: this.$t('random')}
                ]
            }
        },
        methods: {
            async store() {
                const isValid = await this.$refs.storeForm.validate();
                if (isValid) {
                    PrepStudentService.createClassesFromPlacement(this.form)
                                      .then(response => {
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(e => {
                                          if (e.data.message) {
                                              this.$toast.error(this.$t('api.' + e.data.message));
                                          }
                                      });
                }
            }
        }
    }
</script>
